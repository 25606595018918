import * as styles from '../styles/hva-er-vippeextensions.module.scss';
import ArticleLayout from '../components/articleLayout/ArticleLayout';
import Grid from "@material-ui/core/Grid";
import PageTitle from "../components/contentHeadings/PageTitle";
import React from 'react';
import SeeAvailableButton from "../components/button/SeeAvailableButton";
import Seo from "../components/Seo";
import SubHeading from "../components/contentHeadings/SubHeading";
import TertiaryHeading from "../components/contentHeadings/TertiaryHeading";
import { Link } from "gatsby";
import {StaticImage} from "gatsby-plugin-image";
import outputPixelDensities from "../data/defaultPixelDensities";
import Quote from "../components/quote/Quote";

// Inline critical CSS to prevent CLS
const inlineStyle = {
    maxWidth: "50vw - 28px"
};

const Vippeextensions = props => {
    const img1 = <StaticImage
        src={"../images/vippeextensions/hva_er_vippeextensions/1.jpg"}
        alt={""}
        width={500}
        sizes={"(min-width: 960px): 214px, calc(50vw - 40px)"}
        style={inlineStyle}
        outputPixelDensities={outputPixelDensities}
    />;

    const img2 = <StaticImage
        src={"../images/vippeextensions/hva_er_vippeextensions/2.jpg"}
        alt={""}
        width={500}
        sizes={"(min-width: 960px): 214px, calc(50vw - 40px)"}
        style={inlineStyle}
        outputPixelDensities={outputPixelDensities}
    />;

    const img3 = <StaticImage
        src={"../images/vippeextensions/hva_er_vippeextensions/3.jpg"}
        alt={""}
        width={500}
        sizes={"(min-width: 960px): 214px, calc(50vw - 40px)"}
        style={inlineStyle}
        outputPixelDensities={outputPixelDensities}
    />;

    const img4 = <StaticImage
        src={"../images/vippeextensions/hva_er_vippeextensions/4.jpg"}
        alt={""}
        width={500}
        sizes={"(min-width: 960px): 214px, calc(50vw - 40px)"}
        style={inlineStyle}
        outputPixelDensities={outputPixelDensities}
    />;

    const img5 = <StaticImage
        src={"../images/vippeextensions/hva_er_vippeextensions/5.jpg"}
        alt={""}
        width={500}
        sizes={"(min-width: 960px): 214px, calc(50vw - 40px)"}
        style={inlineStyle}
        outputPixelDensities={outputPixelDensities}
    />;

    const img6 = <StaticImage
        src={"../images/vippeextensions/hva_er_vippeextensions/6.jpg"}
        alt={""}
        width={500}
        sizes={"(min-width: 960px): 214px, calc(50vw - 40px)"}
        style={inlineStyle}
        outputPixelDensities={outputPixelDensities}
    />;

    return(
        <ArticleLayout>
            <Seo title={"Hva er vippeextensions?"} />
            <section>
                <PageTitle>Hva er vippeextensions?</PageTitle>
                <Quote>Vippeextensions er kunstige hår som blir limt på én og én naturlig vipp, for å skape en fyldig og en forlengende effekt.</Quote>
                <p>Vi har mange forskjellige design, tykkelser, lengder, farger og krøller for å skape ulike effekter. Vi tilpasser valget etter hvordan dine naturlige vipper ser ut, og hva som vil kle dine øyne og ditt ansikt.</p>
                <StaticImage
                    src={'../images/vippeextensions/hva_er_vippeextensions/dorthe.jpg'}
                    alt={"Dame ser seg i speilet og inspiserer sine vippeextensions"}
                    width={960}
                    sizes={"(min-width: 960px): 329px, (min-width: 600px): calc(50vw - 32px), 100vw - 40px"}
                    outputPixelDensities={outputPixelDensities}
                />
                <Grid container spacing={2} className={styles.gallery}>
                    <Grid item xs={6}>
                        { img1 }
                    </Grid>
                    <Grid item xs={6}>
                        { img2 }
                    </Grid>
                </Grid>
            </section>
            <section>
                <p>Vippeextensions limes på dine naturlige vipper. Det betyr at de vokser ut og faller av med dine naturlige vipper. På den måten vil de ikke slite eller ødelegge vippene dine på noen måte. Å få vippeextensions er helt smertefritt både under behandlingen og senere. Du vil få en pad under øyet (for å dekke til undervippene) som kan kjennes litt kjølig ut og du får et teppe over deg. Mange sovner under behandlingen. Extensions-hår blir limt på toppen av dine naturlige vipper, ikke på huden, så du vil ikke kjenne noen ting utenom at vi roter litt rundt i vippene dine.</p>
                <SeeAvailableButton>Se ledige timer</SeeAvailableButton>
                <StaticImage
                    src={'../images/vippeextensions/hva_er_vippeextensions/close_up.jpg'}
                    alt={"Nærbilde av hender som legger vippeextensions på en kunde"}
                    width={960}
                    sizes={"(min-width: 960px): 329px, (min-width: 600px): calc(50vw - 32px), 100vw - 40px"}
                    outputPixelDensities={outputPixelDensities}
                />
                <br/>
                <br/>
                <p>Alle produkter vi bruker er både veganske og dyrevennlige. Vi bruker ikke vipper laget av minkhår eller andre dyrehår. Hårene/vippene vi bruker er laget av Polybutylene terephthalate (PBT), som er en type elastisk plast som også brukes i tannbørste- og dukkehår. Limet vi bruker er spesiallaget til extensions og holder svært godt. Det inneholder ikke formaldehyd.</p>
            </section>
            <section>
                <SubHeading>Hva må man gjøre selv etter timen?</SubHeading>
                <p>For at vippeextensions-limet skal holde seg er det viktig å følge noen huskeregler. Så lenge du følger reglene nedenfor vil vippeextensions-ene vare helt til de naturlige vippene blir byttet ut.</p>
                <TertiaryHeading>De første 24 timene</TertiaryHeading>
                <ul>
                    <li>Det tar 24 timer før vippelimet har størknet helt. Dette første døgnet er derfor kritiske for at vippene skal vare. I løpet av de første 24 timene etter å ha tatt vippeextensions er det viktig å ikke eksponere vippene for fuktighet. Både direkte eksponering til vann og høy luftfuktighet vil gjøre at limet størkner for raskt og bindingen vil bli skjør. Unngå å dusje fullstendig det første døgnet. Selv om du unngår å få vann direkte på vippene vil det bli høy luftfuktighet og mikroskopisk sprut i rommet.</li>
                    <li>Unngå høy varme (for eksempel soling/solstudio) de første 24 timene.</li>
                    <li>Etter 24 timer har limet størknet og du kan fritt bade og dusje uten at dette skader vippene.</li>
                </ul>
                <StaticImage
                    src={'../images/vippeextensions/hva_er_vippeextensions/dayanis_extensions.jpg'}
                    alt={"En vippetekniker legger vippeextensions på en kunde"}
                    width={960}
                    sizes={"(min-width: 960px): 329px, (min-width: 600px): calc(50vw - 32px), 100vw - 40px"}
                    outputPixelDensities={outputPixelDensities}
                />
                <br/>
                <br/>
                <TertiaryHeading>Holdbarhet</TertiaryHeading>
                <ul>
                    <li>Unngå å få olje eller oljebaserte produkter på vippene. Dette kan få limet til å løsne. Så langt det er mulig, unngå å bruke oljebaserte produkter i ansiktet.</li>
                    <li>vippene rene. Huden produserer olje på egenhånd og denne kan løse opp limet. Det beste er å bruke vipperens daglig (kan kjøpes i salongen). Videre kan det føre til sykdom/infeksjon dersom man går lang tid uten å vaske vippene (enten man har extensions eller ikke). Usikker på hvordan du renser vippene skikkelig? <Link to={'/rens-av-vippeextensions'}>Les mer om det HER.</Link></li>
                    <li>Ikke plukk eller dra i vippene.</li>
                    <li>Ikke sov på ansiktet. Vippene vil falle av raskere dersom de blir most ned i puten. Dette fører også ofte til at vippene på det ene øyet faller av før det andre, fordi man sover på siden.</li>
                    <li>Dersom vippene faller av raskt selv om du har vært forsiktig ta kontakt så raskt som mulig så vi kan fikse dem!</li>
                </ul>
            </section>
            <Grid container spacing={2} className={styles.gallery}>
                <Grid item xs={6}>
                    { img3 }
                </Grid>
                <Grid item xs={6}>
                    { img4 }
                </Grid>
            </Grid>
            <section>
                <SubHeading>Hvor lenge vil de holde?</SubHeading>
                <p>Dine naturlige vipper er hår, og hår vokser. Etter 2-4 uker vil du se at extensions-ene ligger lenger ut på håret enn de gjorde før - og det er fordi vippene dine vokser. Dine naturlige vipper går igjennom en syklus hvor du mister noen hår, og nye hår vokser ut for å ta plassen deres. Man mister i gjennomsnitt 1-3 vipper hver dag. Etter mellom én og tre måneder vil alle dine naturlige vipper ha byttet seg ut. </p>
                <p>De nye vippene som vokser ut har ikke vippeextensions på seg, og man vil derfor gradvis få færre og færre vipper som har vippeextensions. Det er på grunn av dette at man ofte trenger påfyll etter rundt tre uker. Etter ca. tre uker er det vanlig å ha mistet rundt halvparten av vippene. Når du kommer tilbake til salongen for å ta påfyll setter vi extensions-hår på de nye vippene dine som har vokst ut. </p>
                <StaticImage
                    src={'../images/vippeextensions/hva_er_vippeextensions/amanda.jpg'}
                    alt={"Dame ser seg i speilet og inspiserer sine vippeextensions"}
                    width={960}
                    sizes={"(min-width: 960px): 329px, (min-width: 600px): calc(50vw - 32px), 100vw - 40px"}
                    outputPixelDensities={outputPixelDensities}
                />
                <br/>
                <br/>
                <p>Etter én uke vil man i gjennomsnitt ha mistet rundt 21 vipper, etter to uker 42 vipper og etter tre uker 63 vipper. Alle er imidlertid forskjellige og noen mister vipper raskere enn dette, og andre senere. I tillegg er dette kun et gjennomsnitt; vipper vokser og faller av i sykluser slik at man kan gå flere dager uten å miste en eneste vippe, for så å miste mange på en gang – eller motsatt.</p>
                <p>Enkelte opplever også perioder (ofte i løpet av våren eller høsten) hvor man mister flere vipper enn vanlig (”seasonal shedding”). Dette er normalt og går over etter noen uker.</p>
                <p>Vi kan ikke med sikkerhet garantere hvor lenge extensions vil holde på deg før du trenger påfyll, fordi det kommer an på hvordan dine naturlige vipper vokser, og hvordan du behandler dem. Et godt utgangspunkt er imidlertid tre uker. </p>
            </section>
            <Grid container spacing={2} className={styles.gallery}>
                <Grid item xs={6}>
                    { img5 }
                </Grid>
                <Grid item xs={6}>
                    { img6 }
                </Grid>
            </Grid>
        </ArticleLayout>
    )
};

export default Vippeextensions;